import Peer, {DataConnection} from "peerjs";
import {store} from "@/store/store";
import Vue from "vue";
import {throttle} from "throttle-debounce";
import {Character, Mission} from "@/interfaces";
import {PDFTYPE} from "@/helpers/PDFTYPE";

const MOUSE_POSITION_UPDATE_RATE_PER_SECOND = 30;

export const EventBus = new Vue();

const PORT = location.protocol === "https:" ? 443 : 80;
export const peer = new Peer({
  host: "noxius-webrtc.herokuapp.com",
  port: PORT,
  path: "/noxius-webrtc",
  config: {
    iceServers: [
      {
        urls: "stun:stun.l.google.com:19302"
      },
      {
        urls: "stun:stun1.l.google.com:19302"
      }
    ]
  },
  debug: 2
});

const connections: Peer.DataConnection[] = [];

const sendCurrentBoardStatus = (conn: DataConnection) => {
  const characters = store.state.characters.filter((character: Character) =>
    store.state.activePDFids.find(id => id == character.ID)
  );
  const missions = store.state.missions.filter((mission: Mission) =>
    store.state.activePDFids.find(id => id == mission.ID)
  );
  conn.send({
    type: "board_status",
    data: {
      characters,
      missions
    }
  });
};

function handleReceivedData(data: any) {
  if (data.type === "relay_connection") {
    if (!connections.find(connection => connection.peer === data.data)) {
      console.log("connecting to new peer via relay");
      // eslint-disable-next-line
            connectToPeer(data.data)
    } else {
      console.log("skipped connecting to peer, already connected.");
    }
  } else if (data.type === "position") {
    EventBus.$emit("position", data.data);
  } else if (data.type === "mouse_position") {
    // Using Event Bus because it's much, MUCH faster than a vuex detour
    EventBus.$emit("mouse_position", data.data);
  } else if (data.type === PDFTYPE.MISSION || data.type === PDFTYPE.CHARACTER) {
    store.dispatch("loadPDF", {
      element: data.data,
      type: data.type
    });
  } else if (data.type === "board_status") {
    console.log("received board status");
    data.data.characters.forEach((character: Character) => {
      store.dispatch("loadPDF", {
        element: character,
        type: PDFTYPE.CHARACTER
      });
    });
    data.data.missions.forEach((mission: Mission) => {
      store.dispatch("loadPDF", {
        element: mission,
        type: PDFTYPE.MISSION
      });
    });
  } else if (data.type === "items_state") {
    EventBus.$emit("update_items_state", data.state);
  } else if (data.type === "dice_throw") {
    EventBus.$emit("dice_throw", data.data);
  } else if (data.type === "set_dice_window") {
    store.commit("setDiceWindow", data.data);
  }
}

function becomeNewHost() {
  console.log("I'm the host now! ...But it's not implemented yet");
}

// TODO Migrate to new host based on connections.sort(alphabetical)[0]
function handleHostDisconnect() {
  console.log(
    "Lost connection to the horst, share your URL with other players to let them rejoin the game"
  );
  window.location.hash = store.state.peerId || "";

  // This should be the same player on all clients
  // TODO appears to be not working yet
  const player = store.state.players.sort((a: string, b: string) =>
    a.localeCompare(b)
  )[0];
  console.log(player);
  if (player == store.state.peerId) {
    becomeNewHost();
  }
}

function onNewConnection(conn: Peer.DataConnection) {
  console.log("new connection: " + conn.peer);
  console.log(conn);

  store.commit("addPlayer", conn.peer);

  // Create new mouse cursor on canvas
  EventBus.$emit("new_player", {
    id: conn.peer
  });

  conn.on("close", () => {
    store.commit("removePlayer", conn.peer);
    EventBus.$emit("player_disconnect", conn.peer);

    if (conn.peer == store.state.connectId) {
      handleHostDisconnect();
    }
  });
}

// Client connections
function connectToPeer(id: string) {
  const conn = peer.connect(id);
  conn.on("open", function() {
    window.location.hash = conn.peer;
    console.log("connection opened @" + Date.now());
    onNewConnection(conn);
    // Receive messages
    conn.on("data", function(data) {
      handleReceivedData(data);
    });

    store.watch(
      () => store.getters.loadingPDFs,
      n => {
        // When false: All PDFs loaded, request their position & rotation
        if (n === false) {
          conn.send({
            type: "request_items_position"
          });
        }
      }
    );
  });

  connections.push(conn);
}

const sendData = (data: any) => {
  connections.forEach(connection => {
    connection.send(data);
  });
};

const sendDataToConnection = (conn: DataConnection, data: any) => {
  conn.send(data);
};

const sendDataThrottled = throttle(
  1000 / MOUSE_POSITION_UPDATE_RATE_PER_SECOND,
  (data: any) => {
    sendData(data);
  }
);

window.addEventListener("beforeunload", function(evt) {
  connections.forEach(connection => connection.close());
  return null;
});

peer.on("open", function(id) {
  // store.commit("setPeerId", id);
  store.commit("addPlayer", id);
});

// Host specific events
peer.on("connection", function(conn) {
  onNewConnection(conn);

  // Relay new connection to other peers
  console.log("relaying connection to other clients");
  sendData({
    type: "relay_connection",
    data: conn.peer
  });

  // Receive messages
  conn.on("data", function(data) {
    handleReceivedData(data);

    if (data.type === "request_items_position") {
      store.dispatch("requestItemsState", conn);
    }
  });

  conn.on("open", () => {
    sendCurrentBoardStatus(conn);
  });

  connections.push(conn);
});

export const webRTC = {
  connectToPeer,
  sendData,
  sendDataToConnection,
  sendDataThrottled
};
