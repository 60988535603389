import {generatePaperObjectsFromDataUrl} from "@/services/paperObjectGenerator";
import paper from "paper";
import {PDFTYPE} from "@/helpers/PDFTYPE";

const RENDER_SCALE = 2;

export const renderPageIntoCanvasDataURL = page => {
  const viewport = page.getViewport({ scale: RENDER_SCALE });

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  canvas.height = viewport.height;
  canvas.width = viewport.width;
  return page
    .render({
      canvasContext: context,
      viewport: viewport
    })
    .promise.then(() => {
      return Promise.resolve(canvas.toDataURL());
    });
};

export const loadCharacterPDFIntoDataURL = async url => {
  // eslint-disable-next-line no-undef
  // eslint-disable-next-line no-undef
  const loadingTask = pdfjsLib.getDocument(url);
  return loadingTask.promise.then(pdf => {
    // console.log(pdf.numPages);
    return pdf.getPage(1).then(page => {
      return renderPageIntoCanvasDataURL(page);
    });
  });
};

export const loadMissionPDFIntoDataURLsWithClipgroups = async mission => {
  // eslint-disable-next-line no-undef
  // eslint-disable-next-line no-undef
  const pdf = await pdfjsLib.getDocument(mission.url).promise;
  const dataURLsWithClipgroups = [];

  // "Reverse" for loop so the draw order of pages is correct
  for (let i = pdf.numPages; i >= 1; i--) {
    const page = await pdf.getPage(i);
    const dataUrl = await renderPageIntoCanvasDataURL(page);
    const clipgroup = mission.clipgroups?.find(
      clipgroup => clipgroup.pageNumber == i
    )?.clipgroups;
    const dataUrlWithClipgroup = {
      dataUrl,
      clipgroup
    };
    dataURLsWithClipgroups.push(dataUrlWithClipgroup);
  }
  return dataURLsWithClipgroups;
};

export const loadPDFIntoCanvas = async (pdfObject, type) => {
  if (type === PDFTYPE.CHARACTER) {
    const dataUrl = await loadCharacterPDFIntoDataURL(pdfObject.url);
    generatePaperObjectsFromDataUrl(
      paper,
      dataUrl,
      pdfObject.clipgroups,
      pdfObject.ID
    );
  } else if (type === PDFTYPE.MISSION) {
    const dataUrlsWithClipgroups = await loadMissionPDFIntoDataURLsWithClipgroups(
      pdfObject
    );
    dataUrlsWithClipgroups.forEach(dataUrlWithClipgroup => {
      generatePaperObjectsFromDataUrl(
        paper,
        dataUrlWithClipgroup.dataUrl,
        dataUrlWithClipgroup.clipgroup,
        pdfObject.ID
      );
    });
  }
};

/*
    pdf => {
            const promises = [];
            for (let i = 1; i <= pdf.numPages; i++) {
                promises.push(pdf.getPage(i))
            }

            await Promise.all(promises).then(values => {
                values.forEach(value => console.log(value))
            })
        }
 */

/*
.then(page => renderPageIntoCanvasDataURL(page).then(dataUrl => {
            dataURLs.push(dataUrl)
            console.log(dataURLs);
            resolvedPromises++;
            if(resolvedPromises >= pdf.numPages) {

            }
 */
