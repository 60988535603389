












import Vue from "vue";

export default Vue.extend({
  name: "character-box",
  props: ["character"]
});
