




















































































































import Vue from "vue";
import PlayCanvas from "@/components/PlayCanvas.vue";
import DebugComponent from "@/components/DebugComponent.vue";
import Modal from "@/components/Modal.vue";
import CharacterBox from "@/components/CharacterBox.vue";
import MissionBox from "@/components/MissionBox.vue";
import {PDFTYPE} from "@/helpers/PDFTYPE";
import LoadingScreen from "@/components/LoadingScreen.vue";
import DiceRoll from "@/components/DiceRoll.vue";

export default Vue.extend({
  name: "App",
  data: function() {
    return {
      PDFTYPE: PDFTYPE,
      url: window.location.origin
    };
  },
  components: {
    DiceRoll,
    LoadingScreen,
    MissionBox,
    CharacterBox,
    Modal,
    PlayCanvas,
    DebugComponent
  },
  computed: {
    characters(): [] {
      return this.$store.state.characters;
    },
    missions(): [] {
      return this.$store.state.missions;
    }
  },
  methods: {
    copyLink() {
      (this.$refs.copyLink as HTMLInputElement).select();
      document.execCommand("copy");
      (this.$refs.copyButton as HTMLElement).innerText = "Kopiert!";
      setTimeout(() => ((this.$refs.copyButton as HTMLElement).innerText = "Kopieren"), 1500);
    }
  }
});
