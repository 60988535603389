<template>
  <canvas
    id="gameCanvas"
    ref="canvas"
    resize="true"
    @wheel="zoomCanvas"
  ></canvas>
</template>

<script>
import Vue from "vue";
import paper from "paper";
import {EventBus} from "@/services/webRTC";
import {updateItemsState} from "@/helpers/helper-functions";
import {mouseDownHandler, mouseDragHandler, mouseMoveHandler} from "@/helpers/view-event-handlers";

const CHARACTER = 0;
const MISSION = 1;

export default Vue.extend({
  name: "PlayCanvas",
  data() {
    return {
      view: null,
      lastViewCenter: null,
      viewStartRotation: 0,
      eventStartCoordinate: [0, 0],
      project: null,
      pdfs: ["bandit.pdf"]
    };
  },
  computed: {
    lastMousePoint() {
      return this.$store.state.lastMousePoint;
    }
  },
  created() {
    paper.install(window);
  },

  mounted() {
    paper.setup(this.$refs.canvas);
    this.project = paper.project;
    this.$store.commit("setPaper", paper);
    new paper.Layer({
      name: "pdfs"
    });
    new paper.Layer({
      name: "cursors"
    });

    this.view = paper.project.view;
    this.lastViewCenter = this.view.center;

    this.view.onMouseDown = mouseDownHandler.call(this);
    this.view.onMouseMove = mouseMoveHandler();
    this.view.onMouseDrag = mouseDragHandler.call(this);

    EventBus.$on("position", data => {
      const target = paper.project.getItem({ name: data.name });
      if (!target) {
        return console.warn(
          "[DESYNC] Received instructions to move an object that does not exist on the canvas. Might happen when the app was minimised."
        );
      }
      target.position = new paper.Point(data.x, data.y);
      target.rotation = data.r;
    });

    EventBus.$on("mouse_position", data => {
      paper.project.layers.cursors.children[data.peer].position = [
        data.x,
        data.y
      ];
    });

    EventBus.$on("new_player", data => {
      // Create new mouse cursor on canvas
      const newCursor = new paper.Shape.Circle({
        radius: 10,
        center: [80, 50],
        fillColor: new paper.Color(Math.random(), Math.random(), Math.random()),
        name: data.id
      });
      paper.project.layers.cursors.addChild(newCursor);
      this.$noty.success("Ein Spieler hat das Spiel betreten.");
    });

    EventBus.$on("player_disconnect", data => {
      console.log("received player disconnect event");
      // Delete cursor of player
      paper.project.layers.cursors.children[data].remove();

      this.$noty.error("Ein Spieler hat das Spiel verlassen.");
    });

    EventBus.$on("update_items_state", state => {
      console.log("update items state");
      console.log(state);
      updateItemsState(paper, state);
    });
  },
  methods: {
    zoomCanvas(e) {
      let change = 0.1;
      if (e.deltaY > 0) {
        change = -change;
      }

      // Restrict zooming
      if (e.deltaY > 0 && this.view.zoom <= 0.15) return;
      if (e.deltaY < 0 && this.view.zoom >= 4) return;

      this.view.zoom += change;
    }
  }
});
</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
  background-color: #eee;
}
</style>
