import {findAngle, logClick} from "@/helpers/helper-functions";
import {store} from "@/store/store";
import {backendService} from "@/services/backendService";

export function mouseDragHandler() {
  return event => {
    // The first lastMousePoint comes from onMouseDown.
    this.lastViewCenter = this.view.center;
    if (event.event.buttons == 1 || event.event.buttons == 4) {
      // Pan view
      this.view.center = this.view.center.add(
        this.lastMousePoint.subtract(event.point)
      );
    } else if (event.event.buttons == 2) {
      // Rotate view
      const angle =
        (findAngle(this.eventStartCoordinate, this.view.center, event.point) *
          180) /
        Math.PI;

      this.view.rotation = this.viewStartRotation + angle;
    }
  };
}

export function mouseMoveHandler() {
    const mousePosition = "mp"
  return event => {
    backendService.sendVolatileDataThrottled({
      type: mousePosition,
      data: {
        x: event.point.x,
        y: event.point.y,
        peer: store.state.socketId
      }
    });
  };
}

export function mouseDownHandler() {
  return event => {
    this.$store.commit(
      "setLastMousePoint",
      event.point.add(this.view.center.subtract(this.lastViewCenter))
    );
    if (event.event.buttons == 1) {
      logClick(event.point);
    }
    this.lastViewCenter = this.view.center;
    this.viewStartRotation = this.view.rotation;
    this.eventStartCoordinate = event.point;
  };
}
