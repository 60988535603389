import Vuex from "vuex";
import Vue from "vue";
import {ApiClient} from "@/services/api";
import {getNonce} from "@/services/get-parameters";
import {Character, Mission} from "@/interfaces";
import {PDFTYPE} from "@/helpers/PDFTYPE";
import {loadPDFIntoCanvas} from "@/services/loadPDF.js";
import {generateItemState} from "@/helpers/helper-functions";
import paper from "paper";
import {backendService} from "@/services/backendService";

Vue.use(Vuex);

const api = new ApiClient(getNonce());

const activePDFids: number[] = [];
const loadingPDFids: number[] = [];
const players: string[] = [];
export const store = new Vuex.Store({
    state: {
        peerId: null,
        socketId: "",
        connectId: "",
        wpNonce: null,
        characters: [],
        missions: [],
        lastMousePoint: [0, 0],
        logClicks: false,
        activePDFids,
        loadingPDFids,
        characterWindow: true,
        missionWindow: false,
        inviteWindow: false,
        diceWindow: false,
        paper: paper, // global paper instance
        loading: true,
        connectedToBackend: false,
        isClient: false,
        initialLoad: true,
        players
    },
    getters: {
        loadingPDFs(state) {
            return state.loadingPDFids.length > 0;
        },
        connectedToBackend(state) {
            return state.connectedToBackend
        },
        peerId(state) {
            return state.peerId;
        }
    },
    mutations: {
        setConnectedToBackend(state, payload) {
            state.connectedToBackend = payload
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setInitialLoad(state, payload) {
            state.initialLoad = payload;
        },
        makeClient(state) {
            state.isClient = true;
        },
        setPaper(state, paper) {
            state.paper = paper;
        },
        setPeerId(state, data) {
            state.peerId = data;
        },
        setSocketId(state, data) {
            state.socketId = data;
        },
        setConnectId(state, data) {
            state.connectId = data;
        },
        setNonce(state, data) {
            state.wpNonce = data;
        },
        setCharacters(state, characters) {
            state.characters = characters;
        },
        setMissions(state, missions) {
            state.missions = missions;
        },
        setLastMousePoint(state, point) {
            state.lastMousePoint = point;
        },
        logClicks(state, log) {
            state.logClicks = log;
        },
        addActivePDFid(state, id) {
            state.activePDFids.push(id);
        },
        removeActivePDFid(state, id: number) {
            state.activePDFids = state.activePDFids.filter(theid => theid != id);
        },
        addLoadingPDFid(state, id: number) {
            state.loadingPDFids.push(id);
        },
        removeLoadingPDFid(state, id: number) {
            state.loadingPDFids = state.loadingPDFids.filter(theid => theid != id);
        },

        setCharacterWindow(state, active: boolean) {
            state.characterWindow = active;
        },
        setMissionWindow(state, active: boolean) {
            state.missionWindow = active;
        },
        setDiceWindow(state, active: boolean) {
            state.diceWindow = active;
        },
        setInviteWindow(state, active: boolean) {
            state.inviteWindow = active;
        },

        addPlayer(state, id) {
            state.players.push(id);
        },
        removePlayer(state, id) {
            state.players = state.players.filter(player => player != id);
        }
    },
    actions: {
        loadCharacters({commit}) {
            return api.getAvailableCharacters().then(data => {
                commit("setCharacters", data.data);
            });
        },
        loadMissions({commit}) {
            return api.getAvailableMissions().then(data => {
                commit("setMissions", data.data);
            });
        },

        async broadcastLoadPDF(
            {dispatch, commit},
            payload: { element: Character | Mission; type: PDFTYPE }
        ) {
            // broadcast to other clients
            backendService.sendData({
                type: payload.type,
                data: payload.element
            });
            // And Load PDF on client
            await dispatch("loadPDF", payload);
            if (payload.type == PDFTYPE.CHARACTER) {
                commit("setCharacterWindow", false);
            } else if (payload.type == PDFTYPE.MISSION) {
                commit("setMissionWindow", false);
            }
        },

        async loadPDF(
            {commit},
            payload: { element: Character | Mission; type: PDFTYPE }
        ) {
            commit("addActivePDFid", payload.element.ID);

            commit("addLoadingPDFid", payload.element.ID);
            await loadPDFIntoCanvas(payload.element, payload.type);
            commit("removeLoadingPDFid", payload.element.ID);
        },

        requestItemsState(store, socketId) {
            const state = generateItemState(store.state.paper);
            console.log("sent state to " + socketId);
            backendService.sendDataToConnection(socketId, {
                type: "items_state",
                state
            });
        },

        setDiceModal({commit}, payload) {
            backendService.sendData({
                type: "set_dice_window",
                data: payload
            });
            commit("setDiceWindow", payload);
        }
    }
});
