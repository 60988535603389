function transformToAssocArray(prmstr: string) {
  const params = {};
  const prmarr = prmstr.split("&");
  for (let i = 0; i < prmarr.length; i++) {
    const tmparr = prmarr[i].split("=");
    // eslint-disable-next-line
        // @ts-ignore
    params[tmparr[0]] = tmparr[1];
  }
  return params;
}

export function getSearchParameters() {
  const prmstr = window.location.search.substr(1);
  return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
}

// Get nonce from get parameter or local storage. Get parameter has priority
export function getNonce() {
  let nonce = "";
  nonce = localStorage.getItem("_nonce") || "";
  const params: { _nonce?: string } = getSearchParameters();
  if (params["_nonce"]) {
    nonce = params["_nonce"];
      history.replaceState && history.replaceState(
          null, '', location.pathname + location.search.replace(/[?&]_nonce=[^&]+/, '').replace(/^&/, '?') + location.hash
      );
  }
  localStorage.setItem("_nonce", nonce);
  // console.log(nonce);
  return nonce;
}
