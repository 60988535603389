import {io} from "socket.io-client";
import {store} from "@/store/store";
import {PDFTYPE} from "@/helpers/PDFTYPE";
import {Character, Mission} from "@/interfaces";
import {EventBus} from "@/services/webRTC";
import {throttle} from "throttle-debounce";

const MOUSE_POSITION_UPDATE_RATE_PER_SECOND = 30;

const URL = process.env.NODE_ENV === "production" ? "https://noxius-online-backend.herokuapp.com/" : "localhost:3000";

const socket = io(URL, {
    transports: ['websocket', 'polling', 'flashsocket']
});


function joinRoom() {
    // Try to join into game room
    const connectId = store.state.connectId;
    socket.emit("joinInto", connectId);
}

socket.on("connect", (payload: never) => {
    console.log("successfully connected to server!")
    store.commit("setSocketId", socket.id);
    store.commit("setConnectedToBackend", true);
    // Try to join into game room
    /*
        const connectId = store.state.connectId;
        socket.emit("joinInto", connectId);
     */
})

socket.on("setRoomId", (roomId: string) => {
    store.commit("setPeerId", roomId)
})

socket.on("request_items_position", (socketid: string) => {
    store.dispatch("requestItemsState", socketid);
})

socket.on("new_player", (socketId: string) => {
    console.log("received new player event");
    store.commit("addPlayer", socketId);

    // Create new mouse cursor on canvas
    EventBus.$emit("new_player", {
        id: socketId
    });
})

socket.on("make_client", () => {
    store.commit("makeClient");
})

socket.on("remove_player", (socketId: string) => {
    store.commit("removePlayer", socketId);
    EventBus.$emit("player_disconnect", socketId);
})

socket.on("event", (data: any) => {
    //console.log("received event");
    //console.log(data);
    if (data.type === "position") {
        EventBus.$emit("position", data.data);
    } else if (data.type === "mp") {
        // Using Event Bus because it's much, MUCH faster than a vuex detour
        EventBus.$emit("mouse_position", data.data);
    } else if (data.type === PDFTYPE.MISSION || data.type === PDFTYPE.CHARACTER) {
        store.dispatch("loadPDF", {
            element: data.data,
            type: data.type
        });
    } else if (data.type === "board_status") {
        console.log("received board status");
        data.data.characters.forEach((character: Character) => {
            store.dispatch("loadPDF", {
                element: character,
                type: PDFTYPE.CHARACTER
            });
        });
        data.data.missions.forEach((mission: Mission) => {
            store.dispatch("loadPDF", {
                element: mission,
                type: PDFTYPE.MISSION
            });
        });
    } else if (data.type === "items_state") {
        console.log("received items_state");
        EventBus.$emit("update_items_state", data.state);
    } else if (data.type === "dice_throw") {
        EventBus.$emit("dice_throw", data.data);
    } else if (data.type === "set_dice_window") {
        store.commit("setDiceWindow", data.data);
    }
})

const send = (event: string, payload?: any) => {
    console.log("sending event " + event);
    return socket.emit(event, payload)
}

const sendEvent = (data: any) => {
    socket.emit("event", data);
};

const sendVolatileData = (data: any) => {
    socket.volatile.emit("event", data);
};

const sendDataToConnection = (target: string, data: any) => {
    socket.emit("event-to-socket", [target, data])
};

const sendDataThrottled = throttle(
    1000 / MOUSE_POSITION_UPDATE_RATE_PER_SECOND,
    (data: any) => {
        sendEvent(data);
    }
);

const sendVolatileDataThrottled = throttle(
    1000 / MOUSE_POSITION_UPDATE_RATE_PER_SECOND,
    (data: any) => {
        sendVolatileData(data);
    }
);

const sendCurrentBoardStatus = (socketId: string) => {
    const characters = store.state.characters.filter((character: Character) =>
        store.state.activePDFids.find(id => id == character.ID)
    );
    const missions = store.state.missions.filter((mission: Mission) =>
        store.state.activePDFids.find(id => id == mission.ID)
    );
    sendDataToConnection(socketId, {
        type: "board_status",
        data: {
            characters,
            missions
        }
    });
};

const sendPosition = (element: any) => {
    return sendEvent({
        type: "position",
        data: {
            x: element.position.x,
            y: element.position.y,
            r: element.rotation,
            name: element.name
        }
    });
}

socket.on("request_board_status", (socketid: string) => {
    sendCurrentBoardStatus(socketid);
})

export const backendService = {
    send,
    sendPosition,
    joinRoom,
    sendData: sendEvent,
    sendVolatileData,
    sendDataToConnection,
    sendDataThrottled,
    sendVolatileDataThrottled,
    sendCurrentBoardStatus
}
