import Vue from "vue";
import App from "./App.vue";
import "./index.css";
import {store} from "@/store/store";
import VueNoty from "vuejs-noty";
import {ApiClient} from "@/services/api";
import {getNonce} from "@/services/get-parameters";
import {backendService} from "@/services/backendService";

Vue.config.productionTip = false;

const NONCE_PAGE_URL = "https://noxius-game.de/play-online/";

const logClicks = false;

// Check if there is a valid nonce, so a valid user. If not, redirect him to get one
(async () => {
    /*
        const urlParams = new URLSearchParams(window.location.search);
      let nonce = urlParams.get("_nonce");
       */
    const nonce = getNonce();
    const isLoggedIn = await new ApiClient(nonce).isLoggedIn();

    // If we don't have a nonce anywhere, get a new one. Transport the hashbang value!
    if (!isLoggedIn) {
        if (process.env.NODE_ENV !== "development") {
            console.log(
                "[custom] Navigating: " + NONCE_PAGE_URL + store.state.connectId
            );
            window.location.href = NONCE_PAGE_URL + "?_host=" + store.state.connectId;
        }
    } else {
        store.commit("setLoading", false);
    }

    // Remove the nonce from the get parameters
    history.replaceState &&
    history.replaceState(
        null,
        "",
        location.pathname +
        location.search.replace(/[&]message=[^&]+/, "").replace(/^&/, "?") +
        location.hash
    );
})();

if (process.env.NODE_ENV === "development" && logClicks) {
    store.commit("logClicks", true);
}

Vue.use(VueNoty, {
    layout: "bottomRight",
    theme: "metroui",
    timeout: 3000,
    visibilityControl: false
});

new Vue({
    render: h => h(App),
    store
}).$mount("#app");

/*
    When we receive a connection to the signaling server,
    1. set the peer ID as a hashbang for easy URL sharing and
    2. dispatch a connect event to the hashbang you joined with
 */
store.watch(
    () => store.getters.connectedToBackend,
    n => {
        // Connect to host from hashbang as soon as possible
        if (window.location.hash.substr(1)) {
            store.commit("setConnectId", window.location.hash.substr(1));
            try {
                backendService.joinRoom();
            } catch (e) {
                console.warn("Could not connect to supplied hash id. This is irrelevant if you just reloaded the page.")
            }
        }
        // If no hashbang is given, join a random room
        else {
            backendService.joinRoom();
        }
    }
);

store.watch(
    () => store.getters.peerId,
    n => {
        if (n === undefined || n === "" || n === null) return;
        window.location.href = "#" + n;
    }
);

store.watch(
    () => store.getters.loadingPDFs,
    n => {
        // When false: All PDFs loaded, request their position & rotation
        if (n === false && store.state.isClient && store.state.initialLoad) {
            console.log("finished loading pdfs, requesting positions");
            store.commit("setInitialLoad", false);
            backendService.send("request_items_position");
        }
    }
);

// Load characters & missions
store.dispatch("loadCharacters");
store.dispatch("loadMissions");

// Prevent normal right click menu from opening;
if (process.env.NODE_ENV == "production") {
    window.oncontextmenu = function () {
        return false;
    };
}
