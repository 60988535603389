<template>
  <div
    ref="container"
    id="dice-roll"
    class="absolute top-0 left-0 w-full h-full"
  >
    <p class="text-center absolute bottom-5 w-full h-24">
      <button
        class="shadow bg-black text-white p-2 rounded"
        @click="randomDiceThrow()"
      >
        Würfeln!
      </button>
    </p>
  </div>
</template>

<script lang="js">
import Vue from "vue";
import * as THREE from "three";
import * as CANNON from "cannon";
import {OrbitControls} from "@kibou/three-orbitcontrols-ts"
import {DiceD6, DiceManager} from "@/helpers/dice";
import {EventBus} from "@/services/webRTC";
import {backendService} from "@/services/backendService";

export default Vue.extend({
  name: "DiceRoll",
  data() {
    return {
      container: null,
      scene: THREE.Scene,
      camera: null,
      renderer: null,
      controls: null,
      stats: null,
      world: null,
      dice: null,
      resizeListener: null,
    };
  },
  mounted() {
// SCENE
    this.scene = new THREE.Scene();
    // CAMERA
    const SCREEN_WIDTH = this.$refs.container.offsetWidth, SCREEN_HEIGHT = this.$refs.container.offsetHeight;
    const VIEW_ANGLE = 45, ASPECT = SCREEN_WIDTH / SCREEN_HEIGHT, NEAR = 0.01, FAR = 20000;
    this.camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR);
    this.scene.add(this.camera);
    this.camera.position.set(-25, 28, 26);
    // RENDERER
    this.renderer = new THREE.WebGLRenderer({antialias: true});
    this.renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    this.resizeListener = window.addEventListener( 'resize', () => {
      this.camera.aspect = this.$refs.container.offsetWidth / this.$refs.container.offsetHeight;
      this.camera.updateProjectionMatrix();

      this.renderer.setSize( this.$refs.container.offsetWidth, this.$refs.container.offsetHeight );
    });

    this.container = document.getElementById('dice-roll');
    this.container.appendChild(this.renderer.domElement);

    // EVENTS
    // CONTROLS

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.enableDamping = true;
    this.controls.enabled = false;

    // STATS
    /*
    this.stats = new THREE.Stats();
    this.stats.domElement.style.position = 'absolute';
    this.stats.domElement.style.bottom = '0px';
    this.stats.domElement.style.zIndex = 100;
    this.container.appendChild( this.stats.domElement );
*/
    const ambient = new THREE.AmbientLight('#ffffff', 0.3);
    this.scene.add(ambient);

    const directionalLight = new THREE.DirectionalLight('#ffffff', 0.5);
    directionalLight.position.x = -1000;
    directionalLight.position.y = 1000;
    directionalLight.position.z = 1000;
    this.scene.add(directionalLight);

    const light = new THREE.SpotLight(0xefdfd5, 1.3);
    light.position.y = 100;
    light.target.position.set(0, 0, 0);
    light.castShadow = true;
    light.shadow.camera.near = 50;
    light.shadow.camera.far = 110;
    light.shadow.mapSize.width = 1024;
    light.shadow.mapSize.height = 1024;
    this.scene.add(light);


    // FLOOR
    const floorMaterial = new THREE.MeshPhongMaterial({color: '#333', side: THREE.DoubleSide});
    const floorGeometry = new THREE.PlaneGeometry(50, 50, 5, 5);
    const floor = new THREE.Mesh(floorGeometry, floorMaterial);
    floor.receiveShadow = true;
    floor.rotation.x = Math.PI / 2;
    this.scene.add(floor);
    // SKYBOX/FOG
    const skyBoxGeometry = new THREE.BoxGeometry(10000, 10000, 10000);
    const skyBoxMaterial = new THREE.MeshPhongMaterial({color: 0x9999ff, side: THREE.BackSide});
    const skyBox = new THREE.Mesh(skyBoxGeometry, skyBoxMaterial);
    // scene.add(skyBox);
    // this.scene.fog = new THREE.FogExp2(0x9999ff, 0.00025);

    ////////////
    // CUSTOM //
    ////////////
    this.world = new CANNON.World();

    this.world.gravity.set(0, -9.82 * 20, 0);
    this.world.broadphase = new CANNON.NaiveBroadphase();
    this.world.solver.iterations = 16;

    DiceManager.setWorld(this.world);

    //Floor
    const floorBody = new CANNON.Body({mass: 0, shape: new CANNON.Plane(), material: DiceManager.floorBodyMaterial});
    floorBody.quaternion.setFromAxisAngle(new CANNON.Vec3(1, 0, 0), -Math.PI / 2);
    this.world.add(floorBody);

    //Walls
    const die = new DiceD6({size: 1.5, backColor: '#000'});
    this.scene.add(die.getObject());
    this.dice = die;

    // Event Bus Events
    EventBus.$on("dice_throw", data => {
      this.executeDiceThrow(data);
    });

    const updatePhysics = () => {
      this.world.step(1.0 / 60.0);
      this.dice.updateMeshFromBody();
    }

    const update = () => {
      this.controls.update();
      // console.log(this.camera.getWorldPosition());
      //console.log(this.camera.getWorldRotation());
      // this.stats.update();
    }

    const render = () => {
      this.renderer.render(this.scene, this.camera);
    }

    const animate = () => {
      if (this.$store.state.diceWindow) {
        updatePhysics();
        render();
        update();
      }
      requestAnimationFrame(animate);
    }

    requestAnimationFrame(animate);
  },
  methods: {
    randomDiceThrow(value) {
      if(!value) {
        value = Math.floor(Math.random() * 6) + 1;
      }
      backendService.sendData({
        type: "dice_throw",
        data: value
      });
      this.executeDiceThrow(value);
    },
    executeDiceThrow(value) {

      const diceValues = [];

      const yRand = Math.random() * 20
      this.dice.getObject().position.x = -15;
      this.dice.getObject().position.y = 2;
      this.dice.getObject().position.z = -15;
      this.dice.getObject().quaternion.x = (Math.random() * 90 - 45) * Math.PI / 180;
      this.dice.getObject().quaternion.z = (Math.random() * 90 - 45) * Math.PI / 180;
      this.dice.updateBodyFromMesh();
      const rand = Math.random() * 5;
      this.dice.getObject().body.velocity.set(25 + rand, 40 + yRand, 15 + rand);
      this.dice.getObject().body.angularVelocity.set(20 * Math.random() - 10, 20 * Math.random() - 10, 20 * Math.random() - 10);

      diceValues.push({dice: this.dice, value});
      DiceManager.prepareValues(diceValues);
    }
  }
})
</script>

<style scoped></style>
