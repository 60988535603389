import {
    handleMouseDown,
    handleMouseDrag,
    handleMouseEnter,
    handleMouseLeave,
    handleMouseUp
} from "@/helpers/game-element-event-handlers";
import {store} from "@/store/store";

export const generatePaperObjectsFromDataUrl = (
    paper,
    dataUrl,
    clipgroups,
    id
) => {
    paper.project.layers.pdfs.activate();
    const raster = new paper.Raster(dataUrl);

    function generateUniqueGroupName(i, group) {
        // Do a regex to create a unique name for the group that uses the amount of groups with that name as identifier
        const regex = new RegExp("g_" + i + "_" + id + "_*", "g");
        const amountOfElementsWithThatName = paper.project.layers.pdfs.getItems(
            {name: regex}
        )?.length;
        group.name = "g_" + i + "_" + id + "_" + amountOfElementsWithThatName;
    }

    raster.onLoad = () => {
        raster.position = [raster.width / 2, raster.height / 2];

        const group = new paper.Group();
        const elements = [];
        if (clipgroups) {
            if (typeof clipgroups === "string" && clipgroups.length > 0) {
                clipgroups = JSON.parse(clipgroups);
            }
            // console.log("clipgroups found:");
            // console.log(clipgroups);
            let i = 0;
            clipgroups.forEach(clipgroup => {
                const clip = new paper.Path(clipgroup);
                clip.closed = true;
                // Create stroke
                const stroke = clip.clone();
                stroke.strokeColor = new paper.Color(0, 0, 0);
                stroke.strokeWidth = 1;
                stroke.dashArray = [4, 6];

                /*
                // Create a rectangle as handler, simplifies rotation
                // Get upper left point and lower right point
                let upper = Number.MAX_SAFE_INTEGER;
                let lower = Number.MIN_SAFE_INTEGER;
                let left = Number.MAX_SAFE_INTEGER ;
                let right = Number.MIN_SAFE_INTEGER;
                clipgroup.forEach(point => {
                    if(point[0] < left) {
                        left = point[0];
                    }
                    if(point[0] > right) {
                        right = point[0]
                    }
                    if(point[1] > lower) {
                        lower = point[1];
                    }
                    if(point[1] < upper) {
                        upper = point[1];
                    }
                })
                // Create rectangle from them
                const handleElement = new paper.Path([left, upper], [left,lower], [right,lower], [right, upper]);
                handleElement.closed = true;
                console.log(handleElement);
                handleElement.fillColor = '#000';
                 */
                // Create group with clipgroup, image and draw stroke on top
                const group = new paper.Group([clip, raster.clone(), /* handleElement,*/ stroke]);
                group.clipped = true;
                // If true, transformations are baked into the element, making absolute rotations impossible
                group.applyMatrix = false;
                generateUniqueGroupName(i, group);

                // Add group to elements array
                elements.push(group);
                i++;
            });
        }
        // No clipgroups defined = take complete page
        else {
            const group = new paper.Group(raster.clone());
            group.applyMatrix = false;
            generateUniqueGroupName(0, group);
            elements.push(group);
        }
        elements.forEach(element => {
            // Attach event handlers
            element.onMouseDrag = handleMouseDrag(element);
            element.onMouseEnter = handleMouseEnter(element);
            element.onMouseLeave = handleMouseLeave(element);
            element.onMouseDown = handleMouseDown(element);
            element.onMouseUp = handleMouseUp(element);
            group.addChild(element);
        });

        if (!store.state.logClicks) {
            group.scale(0.5);
        }
        raster.remove();
    };
};
