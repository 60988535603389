import { findAngle, logClick } from "@/helpers/helper-functions";
import { store } from "@/store/store";
import { backendService } from "@/services/backendService";

export const handleMouseDrag = element => {
  return event => {
    /** Movement **/
    if (event.event.buttons === 1) {
      event.stopPropagation();
      element.position.x += event.delta.x;
      element.position.y += event.delta.y;

      backendService.sendPosition(event.target);
    }

    /** Rotation **/
    else if (event.event.buttons === 2) {
      event.stopPropagation();

      const center = element.startPosition;
      const degree =
        (Math.atan2(
          event.point.y - center.y,
          event.point.x - center.x
        ) * 180) /
        Math.PI;
      let ang = element.startRotation + degree - element.pressAngle;
      if (event.event.shiftKey) {
        ang = ((ang / 15) >> 0) * 15;
      }
      // Round to 1/100 of a degree. Precise enough and reduces data sent
      element.rotation = Math.round(ang * 100) / 100;
      backendService.sendPosition(event.target);
    }

    backendService.sendVolatileDataThrottled({
      type: "mp",
      data: {
        x: event.point.x,
        y: event.point.y,
        peer: store.state.socketId
      }
    });
  };
};

export const handleMouseEnter = (element) => {
  return () => {
      element.hovered = true;
    if (store.state.logClicks) return;
    document.body.style.cursor = "grab";
  };
};

export const handleMouseLeave = (element) => {
  return () => {
      element.hovered = false;
    document.body.style.cursor = "default";
  };
};

export const handleMouseDown = element => {
  return event => {
    if (event.event.buttons === 1) {
      logClick(event.point);
    }

    element.startCoordinate = event.point;
    element.startPosition = element.position;
    element.startRotation = element.rotation;

    element.pressAngle =
      (Math.atan2(
        element.startCoordinate.y - 0 - element.position.y,
        element.startCoordinate.x - 0 - element.position.x
      ) *
        180) /
      Math.PI;

    store.commit("setLastMousePoint", event.point);

    document.body.style.cursor = "grabbing";
    event.stopPropagation();
  };
};

export const handleMouseUp = (element) => {
  return () => {
      if(element.hovered) {
          document.body.style.cursor = "grab";
      } else {
          document.body.style.cursor = "default";
      }
  };
};
