








































































import Vue from "vue";

export default Vue.extend({
  name: "Modal",
  props: [
    "active",
    "close",
    "title",
    "subtitle",
    "loadingCondition",
    "modalWrapperClasses"
  ]
});
