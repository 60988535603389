












import Vue from "vue";
import {webRTC} from "@/services/webRTC";

export default Vue.extend({
  data() {
    return {
      peerid: ""
    };
  },
  name: "DebugComponent",
  methods: {
    connectToPeer() {
      webRTC.connectToPeer(this.peerid);
    }
  }
});
