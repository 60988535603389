/** Makes requests to the backend and handles the results **/
import axios, {AxiosError, AxiosInstance} from "axios";

let apiUrl = "https://noxius-game.de/wp-json/play/v1/";
if (process.env.NODE_ENV === "development") {
  apiUrl = "";
}
const userCheckUrl = "user";

const getClient = (baseUrl = apiUrl) => {
  const options = {
    withCredentials: true,
    baseURL: apiUrl
  };

  const client = axios.create(options);

  client.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      console.warn(error.response);
      return Promise.reject(error);
    }
  );

  return client;
};

class ApiClient {
  client: AxiosInstance;

  constructor(nonce = "") {
    this.client = getClient(apiUrl);

    this.client.interceptors.request.use(config => {
      config.headers["X-WP-Nonce"] = nonce;
      return config;
    });
  }

  async isLoggedIn() {
    return this.client
      .get(userCheckUrl)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.error(error);
      });
  }

  getAvailableCharacters() {
    return this.client
      .get("characters")
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  getAvailableMissions() {
    return this.client
      .get("missions")
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  public get(url: string, conf = {}) {
    return this.client
      .get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  delete(url: string, conf = {}) {
    return this.client
      .delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  head(url: string, conf = {}) {
    return this.client
      .head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  options(url: string, conf = {}) {
    return this.client
      .options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  post(url: string, data = {}, conf = {}) {
    return this.client
      .post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  put(url: string, data = {}, conf = {}) {
    return this.client
      .put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }

  patch(url: string, data = {}, conf = {}) {
    return this.client
      .patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
}

export { ApiClient };

/**
 * Base HTTP Client
 */
export default {
  // Provide request methods with the default base_url
  get(url: string, conf = {}) {
    return getClient()
      .get(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  delete(url: string, conf = {}) {
    return getClient()
      .delete(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  head(url: string, conf = {}) {
    return getClient()
      .head(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  options(url: string, conf = {}) {
    return getClient()
      .options(url, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  post(url: string, data = {}, conf = {}) {
    return getClient()
      .post(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  put(url: string, data = {}, conf = {}) {
    return getClient()
      .put(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  },

  patch(url: string, data = {}, conf = {}) {
    return getClient()
      .patch(url, data, conf)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error));
  }
};
